.title{
    font-size: 3rem;
    font-family: "Assistant";
    text-align: center;
    color:#69443c;
    text-shadow:  2px 1px 4px #00000085;
    margin:1% auto;
    direction: rtl;
}
@media only screen and (max-width: 350px) {
    .title{
        font-size: 2rem;
        font-family: "Assistant";
        text-align: center;
        color:#69443c;
        text-shadow:  2px 1px 4px #00000085;
        margin:1% auto;
        direction: rtl;
    }

}
@media only screen  and (min-width: 350px) and (max-width: 450px) {
    .title{
        font-size: 2.3rem;
        font-family: "Assistant";
        text-align: center;
        color:#69443c;
        text-shadow:  2px 1px 4px #00000085;
        margin:1% auto;
        direction: rtl;
    }

}
@media only screen  and (min-width: 450px) and (max-width: 650px) {
    .title{
        font-size: 2.5rem;
        font-family: "Assistant";
        text-align: center;
        color:#69443c;
        text-shadow:  2px 1px 4px #00000085;
        margin:1% auto;
        direction: rtl;
    }

}
@media only screen  and (min-width: 650px) and (max-width: 850px) {
    .title{
        font-size: 2.8rem;
        font-family: "Assistant";
        text-align: center;
        color:#69443c;
        text-shadow:  2px 1px 4px #00000085;
        margin:1% auto;
        direction: rtl;
    }

}