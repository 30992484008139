.title{
    font-size: 3.5rem;
    font-family: "Assistant";
    text-align: center;
    margin:0 auto;
    padding-top:10px;
    direction: rtl;
    color:#69443c;
    text-shadow:  2px 1px 4px #00000085;
}
.description{
    font-size:1.4rem;
    font-family: "AssistantR";
    text-align: center;
    margin:1% auto;
  width:60%;
    direction: rtl;
}
@media only screen and (max-width: 350px) {

  .title{
    font-size: 2rem;
    font-family: "Assistant";
    text-align: center;
    margin:0 auto;
    padding-top:10px;
    direction: rtl;
    color:#69443c;
    text-shadow:  2px 1px 4px #00000085;
}
.description{
    font-size:1.1rem;
    font-family: "AssistantR";
    text-align: center;
    margin:1% auto;
  width:90%;
    direction: rtl;
}

}
@media only screen  and (min-width: 350px) and (max-width: 450px) {

  .title{
    font-size: 2.2rem;
    font-family: "Assistant";
    text-align: center;
    margin:0 auto;
    padding-top:10px;
    direction: rtl;
    color:#69443c;
    text-shadow:  2px 1px 4px #00000085;
}
.description{
    font-size:1.15rem;
    font-family: "AssistantR";
    text-align: center;
    margin:1% auto;
  width:90%;
    direction: rtl;
}

}
@media only screen  and (min-width: 450px) and (max-width: 550px) {

  .title{
    font-size: 2.5rem;
    font-family: "Assistant";
    text-align: center;
    margin:0 auto;
    padding-top:10px;
    direction: rtl;
    color:#69443c;
    text-shadow:  2px 1px 4px #00000085;
}
.description{
    font-size:1.2rem;
    font-family: "AssistantR";
    text-align: center;
    margin:1% auto;
  width:90%;
    direction: rtl;
}
}
@media only screen  and (min-width: 550px) and (max-width: 750px) {

  .title{
    font-size: 2.8rem;
    font-family: "Assistant";
    text-align: center;
    margin:0 auto;
    padding-top:10px;
    direction: rtl;
    color:#69443c;
    text-shadow:  2px 1px 4px #00000085;
}
.description{
    font-size:1.2rem;
    font-family: "AssistantR";
    text-align: center;
    margin:1% auto;
  width:80%;
    direction: rtl;
}
}
@media only screen  and (min-width: 750px) and (max-width: 950px) {

  .title{
    font-size: 3rem;
    font-family: "Assistant";
    text-align: center;
    margin:0 auto;
    padding-top:10px;
    direction: rtl;
    color:#69443c;
    text-shadow:  2px 1px 4px #00000085;
}
.description{
    font-size:1.2rem;
    font-family: "AssistantR";
    text-align: center;
    margin:1% auto;
  width:70%;
    direction: rtl;
}
}
@media only screen  and (min-width: 950px) and (max-width: 1150px) {

  .title{
    font-size: 3.2rem;
    font-family: "Assistant";
    text-align: center;
    margin:0 auto;
    padding-top:10px;
    direction: rtl;
    color:#69443c;
    text-shadow:  2px 1px 4px #00000085;
}
.description{
    font-size:1.25rem;
    font-family: "AssistantR";
    text-align: center;
    margin:1% auto;
  width:70%;
    direction: rtl;
}
}