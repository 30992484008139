.title{
    font-size: 3.5rem;
    font-family: "Assistant";
    text-align: center;
    margin:0 auto;
    width:80%;
    padding-top:10px;
    direction: rtl;
    color:#69443c;
    text-shadow:  2px 1px 4px #00000085;
}
.description{
    font-size: 1.2rem;
    direction: rtl;
    width:90%;
    color:#2a1a16;
    justify-content: center;
    text-align: center;
    margin: 2% auto;
    font-family: "AssistantR";
}
.center{
    width:50%;
    display: flex;
    justify-content: center;
    margin:2% auto;
}
.image{
    width: 100%;
    object-fit: contain;

}
@media only screen and (max-width: 350px) {

    .title{
        font-size: 2rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        padding-top:10px;
        direction: rtl;
        color:#69443c;
        text-shadow:  2px 1px 4px #00000085;
    }
    .description{
        font-size: 1.1rem;
        direction: rtl;
        width:90%;
        color:#2a1a16;
        justify-content: center;
        text-align: center;
        margin: 2% auto;
        font-family: "AssistantR";
    }
    .center{
        width:90%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
    
    }

}
@media only screen and (min-width: 350px) and (max-width: 450px) {

    .title{
        font-size: 2.3rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        width:95%;
        padding-top:10px;
        direction: rtl;
        color:#69443c;
        text-shadow:  2px 1px 4px #00000085;
    }
    .description{
        font-size: 1.1rem;
        direction: rtl;
        width:90%;
        color:#2a1a16;
        justify-content: center;
        text-align: center;
        margin: 2% auto;
        font-family: "AssistantR";
    }
    .center{
        width:95%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
    
    }

}
@media only screen and (min-width: 450px) and (max-width: 550px) {

    .title{
        font-size: 2.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        width:95%;
        padding-top:10px;
        direction: rtl;
        color:#69443c;
        text-shadow:  2px 1px 4px #00000085;
    }
    .description{
        font-size: 1.2rem;
        direction: rtl;
        width:90%;
        color:#2a1a16;
        justify-content: center;
        text-align: center;
        margin: 2% auto;
        font-family: "AssistantR";
    }
    .center{
        width:95%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
    
    }

}
@media only screen and (min-width: 550px) and (max-width: 650px) {

    .title{
        font-size: 2.8rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        width:95%;
        padding-top:10px;
        direction: rtl;
        color:#69443c;
        text-shadow:  2px 1px 4px #00000085;
    }
    .description{
        font-size: 1.2rem;
        direction: rtl;
        width:90%;
        color:#2a1a16;
        justify-content: center;
        text-align: center;
        margin: 2% auto;
        font-family: "AssistantR";
    }
    .center{
        width:90%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
    
    }

}
@media only screen and (min-width: 650px) and (max-width: 850px) {

    .title{
        font-size: 3rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        width:95%;
        padding-top:10px;
        direction: rtl;
        color:#69443c;
        text-shadow:  2px 1px 4px #00000085;
    }
    .description{
        font-size: 1.2rem;
        direction: rtl;
        width:90%;
        color:#2a1a16;
        justify-content: center;
        text-align: center;
        margin: 2% auto;
        font-family: "AssistantR";
    }
    .center{
        width:85%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
    
    }

}
@media only screen and (min-width: 850px) and (max-width: 1050px) {

    .title{
        font-size: 3.2rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        width:95%;
        padding-top:10px;
        direction: rtl;
        color:#69443c;
        text-shadow:  2px 1px 4px #00000085;
    }
    .description{
        font-size: 1.2rem;
        direction: rtl;
        width:90%;
        color:#2a1a16;
        justify-content: center;
        text-align: center;
        margin: 2% auto;
        font-family: "AssistantR";
    }
    .center{
        width:75%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
    
    }

}
@media only screen and (min-width: 1050px) and (max-width: 1200px) {

    .title{
        font-size: 3.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        width:90%;
        padding-top:10px;
        direction: rtl;
        color:#69443c;
        text-shadow:  2px 1px 4px #00000085;
    }
    .description{
        font-size: 1.2rem;
        direction: rtl;
        width:90%;
        color:#2a1a16;
        justify-content: center;
        text-align: center;
        margin: 2% auto;
        font-family: "AssistantR";
    }
    .center{
        width:75%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
    
    }

}