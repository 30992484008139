.center{
    width:15%;
    display: flex;
    justify-content: center;
    margin:2% auto;
}
.image{
    width: 100%;
    object-fit: contain;
    border-radius:30px;
    box-shadow:  0 0 5px 1px #555;
}

.title{
    font-size: 3.5rem;
    font-family: "Assistant";
    text-align: center;
    margin:2% auto;
    color:#69443c;
    text-shadow:  0 2px 4px rgba(0, 0, 0, 0.274);
    direction: rtl;
}
.description{
    font-size: 1.5rem;
    font-family: "Assistant";
    width:80%;
    text-align: center;
    margin:3% auto;
    direction: rtl;

    color:#482922;
}
@media only screen and (max-width: 450px) {

    .center{
        width:70%;
        display: flex;
        justify-content: center;
        margin:3% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
        border-radius:30px;
        box-shadow:  0 0 5px 1px #555;
    }
    
    .title{
        font-size: 2.2rem;
        font-family: "Assistant";
        text-align: center;
        margin:3% auto;
        color:#69443c;
        text-shadow:  0 2px 4px rgba(0, 0, 0, 0.274);
        direction: rtl;
    }
    .description{
        font-size: 1.1rem;
        font-family: "Assistant";
        width:95%;
        text-align: center;
        margin:4% auto;
        direction: rtl;
    line-height: 1.25;
        color:#482922;
    }

}
@media only screen and (min-width: 450px)  and (max-width: 550px) {

    .center{
        width:50%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
        border-radius:30px;
        box-shadow:  0 0 5px 1px #555;
    }
    
    .title{
        font-size: 2.7rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        color:#69443c;
        text-shadow:  0 2px 4px rgba(0, 0, 0, 0.274);
        direction: rtl;
    }
    .description{
        font-size: 1.2rem;
        font-family: "Assistant";
        width:90%;
        text-align: center;
        margin:3% auto;
        direction: rtl;
    
        color:#482922;
    }

}
@media only screen and (min-width: 550px)  and (max-width: 650px) {

    .center{
        width:40%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
        border-radius:30px;
        box-shadow:  0 0 5px 1px #555;
    }
    
    .title{
        font-size: 3rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        color:#69443c;
        text-shadow:  0 2px 4px rgba(0, 0, 0, 0.274);
        direction: rtl;
    }
    .description{
        font-size: 1.2rem;
        font-family: "Assistant";
        width:80%;
        text-align: center;
        margin:3% auto;
        direction: rtl;
    
        color:#482922;
    }
}
@media only screen and (min-width: 650px)  and (max-width: 750px) {

    .center{
        width:35%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
        border-radius:30px;
        box-shadow:  0 0 5px 1px #555;
    }
    
    .title{
        font-size: 3.2rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        color:#69443c;
        text-shadow:  0 2px 4px rgba(0, 0, 0, 0.274);
        direction: rtl;
    }
    .description{
        font-size: 1.2rem;
        font-family: "Assistant";
        width:70%;
        text-align: center;
        margin:3% auto;
        direction: rtl;
    
        color:#482922;
    }

}
@media only screen and (min-width: 750px)  and (max-width: 950px) {

    .center{
        width:30%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
        border-radius:30px;
        box-shadow:  0 0 5px 1px #555;
    }
    
    .title{
        font-size: 3.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        color:#69443c;
        text-shadow:  0 2px 4px rgba(0, 0, 0, 0.274);
        direction: rtl;
    }
    .description{
        font-size: 1.2rem;
        font-family: "Assistant";
        width:65%;
        text-align: center;
        margin:3% auto;
        direction: rtl;
    
        color:#482922;
    }

}
@media only screen and (min-width: 950px)  and (max-width: 1200px) {

    .center{
        width:25%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
        border-radius:30px;
        box-shadow:  0 0 5px 1px #555;
    }
    
    .title{
        font-size: 3.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        color:#69443c;
        text-shadow:  0 2px 4px rgba(0, 0, 0, 0.274);
        direction: rtl;
    }
    .description{
        font-size: 1.2rem;
        font-family: "Assistant";
        width:60%;
        text-align: center;
        margin:3% auto;
        direction: rtl;
    
        color:#482922;
    }

}