.center{
    width:50%;
    display: flex;
    justify-content: center;
    margin:2% auto;
}
.description{
    font-size: 3rem;
    font-family: "Assistant";
    text-align: center;
    margin:1% auto;
    text-shadow:  2px 1px 4px #00000085;
    direction: rtl;
}
.image{
    width: 100%;
    object-fit: contain;
}
.bold{
    display: inline;
    color:rgb(10, 145, 190);
    text-shadow:  2px 1px 4px #00000085;
}
.backgroundP{
    padding-top: 80px;
}
@media only screen and (max-width: 350px) {


    .center{
        width:90%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .description{
        font-size: 2rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
    .bold{
        display: inline;
        color:rgb(10, 145, 190)
    }
    
}
@media only screen  and (min-width: 350px) and (max-width: 550px) {
    .center{
        width:90%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .description{
        font-size: 2.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
    .bold{
        display: inline;
        color:rgb(10, 145, 190)
    }
    
}
@media only screen  and (min-width: 550px) and (max-width: 650px) {
    .center{
        width:80%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .description{
        font-size: 3rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
    .bold{
        display: inline;
        color:rgb(10, 145, 190)
    }
    
}
@media only screen  and (min-width: 650px) and (max-width: 850px) {
    .center{
        width:75%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .description{
        font-size: 3.2rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
    .bold{
        display: inline;
        color:rgb(10, 145, 190)
    }
    
}
@media only screen  and (min-width: 850px) and (max-width: 1050px) {
    .center{
        width:70%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .description{
        font-size: 3.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
    .bold{
        display: inline;
        color:rgb(10, 145, 190)
    }
    
}