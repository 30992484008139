.center{
    width:20%;
    display: flex;
    justify-content: center;
    margin:2% auto;
}
.description{
    font-size: 3rem;
    font-family: "Assistant";
    text-align: center;
    color:#69443c;
    text-shadow:  2px 1px 4px #00000085;
    margin:1% auto;
    direction: rtl;
}
.image{
    width: 100%;
    object-fit: contain;
    border-radius: 30px;
    box-shadow:0 2px  4px 1px #333;
}
.backgroundP{
    padding-top: 80px;
}
@media only screen and (max-width: 350px) {


    .center{
        width:65%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .description{
        font-size: 2rem;
        font-family: "Assistant";
        text-align: center;
        text-shadow:  2px 1px 4px #00000085;
        margin:1% auto;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
    
}
@media only screen   and (min-width: 350px) and (max-width: 450px) {


    .center{
        width:65%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .description{
        font-size: 2rem;
        font-family: "Assistant";
        text-align: center;
        text-shadow:  2px 1px 4px #00000085;
        margin:1% auto;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
}
@media only screen   and (min-width: 450px) and (max-width: 550px) {
    .center{
        width:40%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .description{
        font-size: 2.5rem;
        font-family: "Assistant";
        text-align: center;
        text-shadow:  2px 1px 4px #00000085;
        margin:1% auto;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
}
@media only screen  and (min-width: 550px) and (max-width: 650px) {
    .center{
        width:35%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .description{
        font-size: 2.8rem;
        font-family: "Assistant";
        text-align: center;
        text-shadow:  2px 1px 4px #00000085;
        margin:1% auto;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
}
@media only screen  and (min-width: 650px) and (max-width: 750px) {
    .center{
        width:30%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .description{
        font-size: 3rem;
        font-family: "Assistant";
        text-align: center;
        text-shadow:  2px 1px 4px #00000085;
        margin:1% auto;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
}
@media only screen  and (min-width: 750px) and (max-width: 950px) {
    .center{
        width:30%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .description{
        font-size: 3.2rem;
        font-family: "Assistant";
        text-align: center;
        text-shadow:  2px 1px 4px #00000085;
        margin:1% auto;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
}
@media only screen  and (min-width: 950px) and (max-width: 1150px) {
    .center{
        width:25%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .description{
        font-size: 3.5rem;
        font-family: "Assistant";
        text-align: center;
        text-shadow:  2px 1px 4px #00000085;
        margin:1% auto;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
}